import styled from 'styled-components';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  useAuthenticationContext,
  ActionTypes,
} from 'context/AuthenticationContext';

import { Logout } from 'services';

import { ReactComponent as Logo } from 'assets/AppBarLogo.svg';

export const APP_BAR_HEIGHT = '4rem';

const Container = styled.div`
  height: ${APP_BAR_HEIGHT};
  background-color: var(--clr-primary);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  justify-content: space-between;
`;

const InformationContainer = styled.div`
  display: flex;
  gap: 2rem;
  color: white;
`;

const ButtonLogout = styled.div`
  cursor: pointer;
`;

const AppBar = () => {
  const navigate = useNavigate();
  const {
    state: { userDetail },
    dispatch,
  } = useAuthenticationContext();

  const handleClickLogout = async () => {
    const token = localStorage.getItem('token');
    try {
      const { data } = await Logout({
        api_key: token ?? '',
      });
      dispatch({
        type: ActionTypes.SET_LOGOUT,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: ActionTypes.SET_LOGOUT,
      });
    } finally {
      navigate('/login');
    }
  };
  return (
    <>
      <Container>
        <Logo />
        <InformationContainer>
          Welcome: {userDetail?.username}
          <ButtonLogout onClick={handleClickLogout}>Logout</ButtonLogout>
        </InformationContainer>
      </Container>
      <Outlet />
    </>
  );
};

export default AppBar;
